export const APP_VERSION = '3.1.0';

export const API_URL = process.env.REACT_APP_API_URL || '';
export const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'debug';
export const COGNITO_USERPOOL_ID = process.env.REACT_APP_COGNITO_USERPOOL_ID || '';
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID || '';
export const ASSESSMENT_GRACE_PERIOD_DAYS = Number(process.env.REACT_APP_ASSESSMENT_GRACE_PERIOD_DAYS) || 30;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const PERIODS = Object.freeze({
  YEAR_1: 1,
  YEAR_2: 2,
  YEAR_3: 3
});

export const statusCodes = {
  CONFLICT_CODE: 409,
  BAD_REQUEST_CODE: 400
};

export const CODE_SENT = 'code_sent';

export const MOBILE_PREFIX = '+1';

// value is lowercase to match with Material UI theme palette
export const DIALOG_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

const COGNITO_ROLES = {
  ADMIN: 'administrator',
  STAFF: 'representatives',
  EMPLOYEE: 'customers'
};

export const ROLES = {
  ADMIN: 'admin',
  STAFF: 'staff',
  EMPLOYEE: 'employee'
};

export const mapCognitoRoles = {
  [COGNITO_ROLES.ADMIN]: ROLES.ADMIN,
  [COGNITO_ROLES.STAFF]: ROLES.STAFF,
  [COGNITO_ROLES.EMPLOYEE]: ROLES.EMPLOYEE
};

export const ROLES_DATA = {
  [ROLES.ADMIN]: { label: 'Admin' },
  [ROLES.STAFF]: { label: 'Representative' },
  [ROLES.EMPLOYEE]: { label: 'Customer' }
};

export const ASSIGNMENT_TYPES = {
  PRIMARY_EMPLOYEE: 'primary_employee',
  SECONDARY_EMPLOYEE: 'secondary_employee',
  PSM: 'psm',
  PSA: 'psa'
};

export const ASSIGNMENT_TYPE_LABELS = {
  [ASSIGNMENT_TYPES.PSM]: 'Senior Partner Lead',
  [ASSIGNMENT_TYPES.PSA]: 'Partner Success Associate'
};

export const NOTIFICATION_TYPES = {
  COMMENT: 'COMMENT',
  GROUP_COMMENT: 'GROUP_COMMENT'
};

export const CERTIFICATION_STEP_TYPES = {
  BASELINE: 'baseline',
  EVALUATION: 'evaluation'
};

export const CERTIFIED_SCORE = 60;
export const INDUSTRY_BENCHMARK = 50;

export const ACTIVITIES_TYPES = {
  CERTIFICATION_STATUS_CHANGE: 'CERTIFICATION_STATUS_CHANGE',
  NEW_COMMENT: 'NEW_COMMENT',
  NEW_GROUP_COMMENT: 'NEW_GROUP_COMMENT'
};

export const SPONSOR_SENIORITY = ['No Sponsor', 'Board Member', 'Senior Manager', 'Middle Manager'];
export const MONITORING_FREQUENCY = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Biannually', 'Yearly', 'Other'];
// export const CERTIFICATION_TASK_STATES = ['Not Started', 'In Progress', 'Done'];

export const ROOT_CAUSES = {
  Representation: {
    'Board of Directors': [
      'Sourcing for new recruits limited to homogeneity of current board member and/or search firm networks',
      'Search limited by narrow subset of skills & experiences of historical board member profile',
      'Seats aren’t opening up often enough to be able to replace with new Black directors'
    ],
    'Senior Level Management': [
      'Org culture makes it difficult for Black employees to break into key networks or form accelerating relationships with current execs',
      'Lack of advocacy, career guidance, & access to accelerating opportunities for Black employees',
      'Lack of adequate lateral hiring process & developed pipeline',
      'Bias in promotion process leading to disproportionately low rates of Black progression in career'
    ],
    'Middle Level Management': [
      'Biases in evaluation process disproportionately harming Black candidates',
      'Black employees getting less effective career guidance',
      'Black employees find it harder to develop accelerating relationships',
      'Black employees find it harder to engage in org culture'
    ],
    'Professionals Level': [
      'Not attracting enough Black applicants',
      'Not sourcing enough Black applicants',
      'Black applicants not making it through recruiting or hiring process',
      "Biases in evaluation process disproportionately harming Black employees' career progression"
    ],
    'Black Representation Overall': [
      'Not attracting or sourcing enough entry level Black applicants',
      'Black applicants not making it through recruiting or hiring process',
      'Black employees find it harder to engage in org culture',
      'Black employees over-represented in back office or non-professional roles that typically have higher turnover'
    ],
    'Black Representation in Top Quartile of Earners': [
      'Barriers to change higher in revenue generating divisions (e.g., tendency to allow poor diversity practices to continue as long as revenue remains strong)',
      'Belief that revenue generating roles are more of a meritocracy',
      'Diversity efforts have historically been most successful in lower paying roles'
    ]
  },
  'Employee Compensation': {
    'Pay Equity Audit': ['Company has not done audit', 'Fear of unflattering results'],
    'Living Wage': [
      "Hasn't been a priority for leadership in the past",
      'Lack of understanding around ROI for paying adequate wages to workers',
      "Company can't afford to increase wages to full amount of living wage"
    ],
    Benefits: [
      'Existing offer is standard package',
      "Company can't afford to increase wages to full amount of living wage"
    ]
  },
  'Inclusive Antiracist Work Environment': {
    'Employee Survey': ['Need to use the Black Equity at Work survey questions']
  },
  'Racially Just Business Practices': {
    'Systematic supplier / vendor diversity program': [
      'Historic partnerships become default over time',
      'Difficulty finding & verifying new diverse suppliers',
      'Small diverse businesses lack ability to gain attention of major companies',
      'Insufficient availability of established Black owned businesses with adequate scale'
    ],
    'Spend with diverse suppliers/vendors': [
      'Historic partnerships become default over time',
      'Difficulty finding & verifying new Black suppliers',
      'Small Black businesses lack ability to gain attention of major companies',
      'Insufficient availability of established Black owned businesses with adequate scale'
    ],
    'Spend with diverse suppliers/vendors in Professional, Scientific and Technical Services segment': [
      'Historic partnerships become default over time',
      'Difficulty finding & verifying new Black suppliers',
      'Small Black businesses lack ability to gain attention of major companies',
      'Insufficient availability of established Black owned businesses with adequate scale'
    ],
    'Services to support Black Equity': [
      'Not knowing where to begin',
      'Potential for impact perceived as limited',
      'Has not been a high priority for organization'
    ]
  },
  'Racial Justice Contributions and Investments': {
    'Cash and approved in-kind contributions to non-profit organizations that increase Black equity': [
      'Inadequate focus on Black equity',
      'No strategy for how to increase Black equity',
      'Contributions too small to achieve significant impact',
      'No systemic change-focused contributions'
    ],
    'Cash deposits to Black-owned or focused financial institutions': [
      'Lack of proactive effort',
      'Investment policies that prevent equity investments',
      'Investment in the wrong financial institutions or instruments (e.g., those that perpetuate or worsen inequity)'
    ]
  }
};
