import { Box } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CertificationMonth from './CertificationMonth';

const useStyles = makeStyles(() => ({
  periodsContainer: ({ isVertical }) => ({
    display: 'flex',
    flexDirection: isVertical ? 'column' : 'row'
  })
}));

export default function CertificationPeriods({ periods, isVertical = false, highlightColor }) {
  const styleClasses = useStyles({ isVertical });
  return (
    <>
      <Box className={styleClasses.periodsContainer} mr={isVertical ? 3 : 0}>
        {periods.map(period => (
          <CertificationMonth
            key={period.title}
            title={period.title}
            asset={period.asset}
            canManageReports={period.canManageReports}
            certificationId={period.certificationId}
            certificationStepId={period.stepId}
            reviewLink={period.reviewLink}
            isHighlighted={period.isHighlighted}
            stepStatus={period?.stepStatus}
            score={period?.score}
            isScoreVisible={period.isScoreVisible}
            addLeftMargin={!isVertical && period.position === 2}
            addTopMargin={isVertical && period.position === 2}
            isDataUnavailable={period.isDataUnavailable}
            highlightColor={highlightColor} />
        ))}
      </Box>
    </>
  );
}
