import { Grid } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { debounce, maxBy } from 'lodash';
import CommentMain from 'src/next/comment/CommentMain';
import Snackbar from 'src/next/mui/Snackbar';
import { CERTIFICATION_STEP_TYPES, ROLES } from 'src/constants';
import { CertificationStepStatus } from 'src/models/certification';
import useStore from 'src/next/store';
import useSnackbar from 'src/next/util/useSnackbar';
import IndicatorView from './IndicatorView';
import { isStepInCycle } from '../certification/certificationUtil';
import routes from '../navigation/routes';

const ANSWER_WAIT = 400;

const { BASELINE, EVALUATION } = CERTIFICATION_STEP_TYPES;

const handleUpdateError = openSnackbar => err => {
  // TODO: For now good enough, but in long run switch to error codes
  if (err.status === 403) {
    openSnackbar('Missing edit privileges', 'error');

    return;
  } else if (err.status === 405) {
    openSnackbar('Cannot change input at this stage', 'error');

    return;
  }

  throw err;
};

export default function IndicatorMain({
  certification,
  certificationStep,
  indicators = [],
  allIndicators = [],
  latestAssessmentStep,
  scores,
  onAfterLast,
  onBeforeFirst
}) {
  const history = useHistory();
  const { hasRole } = useStore(state => state.auth);
  const {
    upsertAnswer,
    upsertEvaluationAnswer
  } = useStore(state => state.certification);
  const { certificationId, certificationStepId, pillarId, indicatorId } = useParams();
  const [snackbarData, openSnackbar] = useSnackbar();

  const isAdmin = hasRole(ROLES.ADMIN);
  const isStaff = hasRole(ROLES.STAFF);
  // Year is ignored because Baseline can be edited at any time as long as no new cycles are created
  const isStepInCurrentCycle = isStepInCycle(certificationStep, certification.currentCycle);
  const isStepInProgress = certificationStep.status === CertificationStepStatus.IN_PROGRESS;
  const indicatorIndex = indicators.findIndex(i => i.id === +indicatorId);
  const indicatorGlobalIndex = allIndicators.findIndex(i => i.id === +indicatorId);
  const assessedIndicators = allIndicators.filter(({ isAssessed }) => isAssessed);
  const indicator = indicators[indicatorIndex];
  const answers = certificationStep.answers.filter(a => a.question.indicator.id === +indicatorId);
  const answerUpdateDate = maxBy(answers.map(a => a.updatedAt), date => new Date(date));
  /* Editing allowed for:
    - Admin users (in all cases)
    - Baseline step (status: In Progress): always editable
    - Evaluation step (status: In Progress): PS team members and Primary users can edit only when assessment period is open
  */
  const isEditable = useMemo(() => (
    isAdmin ||
    (
      isStepInCurrentCycle && isStepInProgress &&
      (certificationStep.type === BASELINE || certification.isAssessmentOpen)
    )
  ), [certification, certificationStep, isAdmin, isStaff]);

  const upsertFunctionByType = {
    [BASELINE]: upsertAnswer,
    [EVALUATION]: upsertEvaluationAnswer
  };
  const updateAnswerDebounced = useCallback(debounce((a, q) => {
    const update = upsertFunctionByType[certificationStep.type];

    update?.(certificationId, certificationStepId, q, a)?.catch(handleUpdateError(openSnackbar));
  }, ANSWER_WAIT), [certificationStep, upsertAnswer, upsertEvaluationAnswer, certificationId, certificationStepId]);
  const seekIndicator = increment => {
    const newIndicator = indicators[indicatorIndex + increment];

    if (newIndicator) {
      return history.push(generatePath(routes.CERTIFICATION_INDICATOR, {
        certificationId,
        certificationStepId,
        pillarId,
        indicatorId: newIndicator.id
      }));
    }

    return increment > 0 ? onAfterLast() : onBeforeFirst();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {
            indicator &&
            <IndicatorView
              indicator={indicator}
              answers={answers}
              position={indicatorGlobalIndex + 1}
              cyclePosition={certificationStep.certificationCycle.position}
              assessedCount={assessedIndicators.length}
              scores={scores}
              availableCycles={certificationStep?.availableGoalCycles ?? []}
              updatedAt={answerUpdateDate}
              isEditable={isEditable}
              assessmentScore={latestAssessmentStep.score?.indicatorScore?.[indicatorId]}
              onNext={() => seekIndicator(1)}
              onPrevious={() => seekIndicator(-1)}
              onAnswerUpdate={updateAnswerDebounced}
            />
          }
        </Grid>
        <Grid item xs={12}>
          {
            indicator &&
            <CommentMain
              questionGroup={indicator}
              certificationStep={certificationStep}
            />
          }
        </Grid>
      </Grid>
      <Snackbar {...snackbarData} />
    </>
  );
}
