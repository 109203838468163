import styled from '@emotion/styled';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { identity, isFinite, isNumber, last, map, pickBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { X as XIcon, Plus as PlusIcon } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import yup from 'src/shared/validation';
import useValidation from 'src/next/util/useValidation';
import InputAdornment from '@mui/material/InputAdornment';

const StyledSelect = styled(Select)`
  max-width: 100%;
  min-width: 240px;
`;

const MultiChoiceLabel = styled(FormControlLabel)`
  & .MuiTypography-root {
    align-self: flex-start;
  }
`;

const MutliChoiceCheckbox = styled(Checkbox)`
  align-self: flex-start;
  padding: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(-1)};
`;

const MultiChoiceDescriptionBox = styled(Box)`
  margin: ${({ theme }) => theme.spacing(1, 0, 2, 4)};
  padding: ${({ theme }) => theme.spacing(0, 2, 0, 3)};
  border-left: 1px solid ${({ theme }) => theme.palette.tertiary.lighter};
`;

const MultiChoiceDescriptionField = styled(props => (
  <TextField
    fullWidth
    multiline
    minRows={4}
    {...props}
  />
))`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`;

const isUnsignedNumber = value => isNumber(value) && value >= 0;

const isCheckbox = target => target.type?.toLowerCase() === 'checkbox';

// Use controlled components so MUI doesn't complain when defaultValue is changed
function useLocalValue(initialValue, onChange) {
  const [value, setValue] = useState(initialValue);
  const firstRun = useRef(true);

  useEffect(() => {
    /*
      As `useEffect` is always run on the first time, just opening the question implies an answer
      with the `NULL` value will be persisted to the database. To avoid such a scenario, `onChange`
      is not called on the first run.
     */
    if (firstRun.current) {
      firstRun.current = false;

      return;
    }

    onChange(value);
  }, [value]);

  return [value, setValue];
}

const Layout = styled(({ heading, children, isComplex = false, ...props }) => (
  <Box {...props}>
    {
      heading &&
      <Typography variant="h5" dangerouslySetInnerHTML={{ __html: heading }} />
    }
    {
      <Box my={isComplex ? 0 : 2}>
        {children}
      </Box>
    }
  </Box>
))`
  color: ${({ theme, disabled }) => disabled && theme.palette.text.disabled};
  padding-bottom: ${({ theme, isComplex }) => isComplex ? 0 : theme.spacing(3)};

  :last-child {
    padding-bottom: 0;
  }
`;

const NotImplementedField = () => (
  <div>
    This question type is not implemented yet.
  </div>
);

function NumberField({ value: initialValue, onChange, inputProps, ...props }) {
  const validationErrorMessage = inputProps?.max
    ? 'This must be less than or equal to 100'
    : 'Max number of digits is 8';
  const [value, error, onValidatedChange] = useValidation(
    initialValue,
    yup.number()
    .typeError('This is a number field')
    .min(0)
    .test('maxValueOrDigits', validationErrorMessage, function (value) {
      if (inputProps?.max) {
        return value <= inputProps.max;
      }
      return value.toString().length <= 8;
    })
    .label('This'),
    onChange,
    event => +event.target.value
  );

  return (
    <TextField
      type='number'
      value={value ?? ''}
      onChange={onValidatedChange}
      error={!!error}
      helperText={error?.message}
      {...props}
    />
  );
}

function PercentageField(props) {
  const theme = useTheme();

  return (
    <NumberField
      inputProps={{
        min: 0,
        max: 100,
        step: 0.01
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            disableTypography
            position='end'
            sx={{ color: theme.palette.text.disabled }}>
            %
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
}

function SingleChoiceField({ options, value, onChange, ...props }) {
  return (
    <StyledSelect
      value={value ?? ''}
      onChange={event => onChange(event.target.value)}
      {...props}
    >
      {options.map(({ id, label }) => (
        <MenuItem
          key={id}
          value={id}
        >
          {label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

function MultiChoiceGroupField({ disabled, options, onChange, value: initialValue = {}, ...props }) {
  const validationSchema = options.reduce((acc, { id, hasDescription }) => {
    return acc.shape({
      [id]: yup.object({
        checked: yup.boolean().required(),
        ...(hasDescription && {
          description: yup.string().when('checked', {
            is: true,
            then: schema => schema.required(),
            otherwise: schema => schema.optional()
          }).label('This')
        })
      })
    });
  }, yup.object().strict(true));

  const [value, error, onValidatedChange] = useValidation(
    initialValue,
    validationSchema,
    onChange,
    identity
  );
  const handleValidatedChange = (key, propertyName) => event => {
    const { target } = event;
    const propertyValue = isCheckbox(target) ? target.checked : target.value;
    const updatedContext = { ...value[key], [propertyName]: propertyValue };
    const uncheckOtherOptionsKey = options.find(option => option.uncheckOtherOptions)?.id;
    const isUcheckOtherOptionsChecked = propertyValue && key === uncheckOtherOptionsKey;

    let result;
    if (isUcheckOtherOptionsChecked) {
      // If 'None of the above' is checked, uncheck all other options
      result = {
        [key]: { checked: true }
      };
    } else {
      // Otherwise, update the selected option and uncheck 'None of the above'
      result = {
        ...value,
        [key]: updatedContext.checked ? updatedContext : {},
        ...uncheckOtherOptionsKey && { [uncheckOtherOptionsKey]: { checked: false } }
      };
    }

    onValidatedChange(pickBy(result, it => it.checked));
  };

  return (
    <FormControl component="fieldset" disabled={disabled} sx={{ width: '100%' }}>
      <FormGroup {...props}>
        {options.map(({ id, label, hasDescription }) => (
          <Box key={id}>
            <MultiChoiceLabel
              sx={{ my: 1 }}
              label={label}
              value={id}
              control={(
                <MutliChoiceCheckbox
                  onChange={handleValidatedChange(id, 'checked')}
                  checked={!!value?.[id]?.checked}
                />
              )}
            />
            {
              hasDescription &&
              <MultiChoiceDescriptionBox>
                <MultiChoiceDescriptionField
                  disabled={disabled || !value?.[id]?.checked}
                  value={value?.[id]?.description || ''}
                  error={!!error?.fields[id]?.description}
                  helperText={error?.fields[id]?.description}
                  onChange={handleValidatedChange(id, 'description')}
                />
              </MultiChoiceDescriptionBox>
            }
          </Box>
        ))}
      </FormGroup>
    </FormControl>
  );
}

function PercentageGroupField({ fields, value: initialValue = {}, onChange, ...props }) {
  const { id: lastId } = last(fields);
  const validationSchema = fields.reduce((acc, { id }) => {
    const schema = yup.number()
    .typeError('This is a number field')
    .test('len', 'Max number of digits is 8', val => val.toString().length <= 8)
    .label('This');

    return acc.shape({
      [id]: id === lastId ? schema.moreThan(0) : schema.min(0)
    });
  }, yup.object());
  const [value, error, onValidatedChange] = useValidation(
    initialValue,
    validationSchema,
    onChange,
    event => ({ ...value, [event.target.name]: +event.target.value })
  );
  const divisor = value?.[lastId];
  const feedbacks = fields
    .filter(field => field.feedback &&
      isUnsignedNumber(divisor) &&
      isUnsignedNumber(value?.[field.id]) &&
      isFinite(value[field.id] / divisor)
    )
    .map(field => ({
      id: field.id,
      text: field.feedback,
      percent: `${((value[field.id] * 100) / divisor).toFixed(2)}%`
    }));

  return (
    <>
      {
        map(fields, ({ id, type, label }) => (
          <Layout
            key={id}
            heading={label}
          >
            <TextField
              type={type}
              name={id}
              value={value?.[id] ?? ''}
              error={!!error?.fields[id]}
              helperText={error?.fields[id]}
              onChange={onValidatedChange}
              {...props}
            />
          </Layout>
        ))
      }
      {
        map(feedbacks, ({ id, text, percent }) => (
          <Layout
            key={id}
            heading={text}
          >
            <Typography variant="h4">{percent}</Typography>
          </Layout>
        ))
      }
    </>
  );
}

function DynamicGroupField({ disabled, value: initialValues = [], onChange, ...props }) {
  const [values, setValues] = useLocalValue(initialValues, onChange);
  const handleContentChange = event => {
    const { name: id, value: content } = event.target;

    setValues(values.map(v => v.id === id ? ({ ...v, content }) : v));
  };
  const addValue = () => {
    setValues([...values, { id: uuidv4(), content: '' }]);
  };
  const removeValue = id => {
    setValues(values.filter(v => v.id !== id));
  };

  return (
    <Grid container direction="column" rowSpacing={1.5}>
      {
        map(values, ({ id, content }) => (
          <Grid key={id} item container>
            <Grid item xs={true}>
              <TextField
                type="text"
                name={id}
                value={content}
                disabled={disabled}
                onChange={handleContentChange}
                multiline
                sx={{ width: '100%' }}
                {...props}
              />
            </Grid>
            <Grid item xs="auto" container justifyContent="center" alignItems="center">
              <IconButton
                color="primary"
                aria-label="remove item"
                component="span"
                disabled={disabled}
                onClick={() => removeValue(id)}
                sx={{ ml: 1 }}
              >
                <XIcon width="16" height="16" />
              </IconButton>
            </Grid>
          </Grid>
        ))
      }
      {
        disabled
          ? null
          : <Grid item container justifyContent="center">
            <Button
              variant="text"
              size="medium"
              startIcon={<PlusIcon width="16" height="16" />}
              onClick={addValue}>
              Add New
            </Button>
          </Grid>
      }
    </Grid>
  );
}

const FIELDS_BY_TYPE = {
  number: NumberField,
  percentage: PercentageField,
  radio: SingleChoiceField,
  checkboxGroup: MultiChoiceGroupField,
  percentageGroup: PercentageGroupField,
  dynamicGroup: DynamicGroupField
};

export default function Question({
  question,
  answer,
  onChange,
  disabled
}) {
  const isComplex = question.fields && question.fields.length > 1;
  const heading = isComplex ? null : question.title;
  const Field = FIELDS_BY_TYPE[question.fieldType] || NotImplementedField;
  const [value, setValue] = useLocalValue(answer?.value, value => onChange({ value }));

  return (
    <Layout
      isComplex={isComplex}
      heading={heading}
      disabled={disabled}
    >
      {
        Field &&
        <Field
          disabled={disabled}
          fields={question.fields}
          options={question.options}
          value={value}
          onChange={setValue}
        />
      }
    </Layout>
  );
}
