import React from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Card,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { MoreVertical as MoreVerticalIcon, X as CloseIcon } from 'react-feather';
import { mapValues } from 'lodash';
import { generatePath } from 'react-router-dom';
import { DATE_FORMAT, prettifyDateTime } from 'src/utils/datetime';
import { CertificationPlatformStatus } from 'src/models/certification';
import { getStatusConfig } from './certificationStatusConfig';
import {
  getCurrentCertificationStep,
  getCurrentCyclePeriodsMetadata,
  isBaseline
} from './certificationUtil';
import BadgeIcon from './BadgeIcon';
import CertificationPeriods from './CertificationPeriods';
import CertificationCountdown from './CertificationCountdown';
import themeByAssessmentType from '../assessment/themeByAssessmentType';
import routes from '../navigation/routes';
import AssessmentLogo from '../assessment/AssessmentLogo';
import usePopperState from '../util/usePopperState';
import ActionsPopper from '../mui/ActionsPopper';
import useDialogState from '../util/useDialogState';
import assessmentType from '../assessment/assessmentType';

const LOGO_SIZE = 108;
const CURRENT_CYCLE_LABEL_SKEW_DEGREES = -33;
const CURRENT_CYCLE_LABEL_HEIGHT = 20;
const COLOR_BY_ASSESSMENT_TYPE = mapValues(themeByAssessmentType, theme => theme.palette.primary.main);
const ADJECTIVE_BY_TYPE = {
  [assessmentType.BEW]: 'Black',
  [assessmentType.HEW]: 'Hispanic'
};

const LogoWrapper = styled(({ updatedAt, type, ...props }) => {
  const formattedDate = prettifyDateTime(updatedAt, DATE_FORMAT);

  return (
    <Box {...props}>
      <AssessmentLogo type={type} />
      {
        updatedAt &&
        <Typography variant='microtext2' color='white' ml={1} mb={1}>
          Updated:<br />
          {formattedDate}
        </Typography>
      }
    </Box>
  );
})`
  background-color: ${({ type }) => COLOR_BY_ASSESSMENT_TYPE[type] ?? 'white'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)};
`;

const PartnerMenu = styled(({ certification, ...props }) => {
  const [
    anchorEl,
    isPopperOpen,
    onPopperOpen,
    onPopperClose
  ] = usePopperState();
  const certificationId = certification?.id;

  return (
    <Box {...props}>
      <IconButton onClick={onPopperOpen}>
        <MoreVerticalIcon />
      </IconButton>

      <ActionsPopper
        anchorEl={anchorEl}
        open={isPopperOpen}
        onClose={onPopperClose}
      >
        <span to={certificationId && generatePath(routes.CERTIFICATION, { certificationId })}>
          Dashboard
        </span>
        <span to={certificationId && generatePath(routes.CERTIFICATION_USERS, { certificationId })}>
          User Management
        </span>
      </ActionsPopper>
    </Box>
  );
})`
  background-color: ${({ theme }) => theme.palette.tertiary.light};
  padding-top: ${({ theme }) => theme.spacing(1)};
`;

const CurrentCycleLabel = styled(({ children, ...props }) => (
  <Box {...props}>
    <Typography variant='microtext3'>
      {children}
    </Typography>
  </Box>
), { shouldForwardProp: prop => prop !== 'isActive' })`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ marginLeft }) => marginLeft && 'margin-left: 8px;'}
  background-color: ${({ theme, isActive }) => isActive ? theme.palette.primary.main : theme.palette.tertiary.main};
  width: 154px;
  height: ${CURRENT_CYCLE_LABEL_HEIGHT}px;
  position: ${({ absolute }) => absolute && 'absolute'};
  left: calc(${({ theme }) => theme.spacing(4)} + ${LOGO_SIZE}px);
  color: white;
  transform: skew(${CURRENT_CYCLE_LABEL_SKEW_DEGREES}deg);

  & > * { // Unskew children
    display: inline-block;
    transform: skew(${-CURRENT_CYCLE_LABEL_SKEW_DEGREES}deg);
  }
`;

const AssessmentTypeButton = styled(props => (
  <Button size='medium' {...props} />
))`
  ${({ type }) => COLOR_BY_ASSESSMENT_TYPE[type] ? `background-color: ${COLOR_BY_ASSESSMENT_TYPE[type]}` : ''};
  padding: ${({ theme }) => theme.spacing(0, 8)};

  &:hover{
    ${({ type }) => COLOR_BY_ASSESSMENT_TYPE[type] ? `background-color: ${COLOR_BY_ASSESSMENT_TYPE[type]}` : ''};
  }
`;

const RoadmapDialog = ({ open, onClose, type, ...props }) => {
  const adjective = ADJECTIVE_BY_TYPE[type] ?? '';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      {...props}
    >
      <Box display='flex' flexDirection='row'>
        <Box pt={4}>
          <AssessmentLogo m={4} type={type} variant='primary' />
        </Box>
        <Box p={2}>
          <Typography component='div' variant='modalHeadline' my={2}>
            A ROADMAP TO EQUITY
          </Typography>
          <Typography>
            MLT {adjective} Equity at Work Certification establishes a clear and comprehensive {adjective} equity standard for employers. The Certification provides the roadmap and the recognition necessary to enable and encourage employers across America to pursue {adjective} equity with the same rigor and results orientation as their pursuit of earnings and other key priorities. The Certification:
            <br />
            <br />
            <ul style={{ paddingLeft: '16px' }}>
              <li>
                Establishes an achievable standard for what “good” looks like and helps employers benchmark
              </li>
              <li>
                Offers critical “how-to” support that meets employers where they are
              </li>
            </ul>
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography component='div' variant='bodyBold'>
            Contact MLT today to inquire about how to add the {adjective} Equity at Work program to your equity roadmap.
          </Typography>
          <AssessmentTypeButton
            onClick={() => window.open('https://mlt.org/partner-with-us/', '_blank')}
            type={type}
            sx={{ my: 2 }}
          >
            Inquire Today
          </AssessmentTypeButton>
        </Box>
        <Box display='flex' alignItems='start' m={1}>
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const ContainerCard = styled(({ disabled, isInactive, children, type, ...props }) => {
  const [isOpen, openDialog, closeDialog] = useDialogState();

  return (
    <>
      <Card disabled={disabled} {...props} >
        {children}
        {
          disabled &&
          <Box className='disable-overlay'>
            <AssessmentTypeButton
              className='learn-more-btn'
              type={type}
              onClick={openDialog}
              disabled={isInactive}
            >
              { isInactive ? 'Deactivated' : 'Learn More' }
            </AssessmentTypeButton>
          </Box>
        }
      </Card>

      <RoadmapDialog
        open={isOpen}
        onClose={closeDialog}
        type={type}
      />
    </>
  );
})`
  position: relative;
  display: flex;
  justify-content: space-between;

  & * {
    pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  }

  .disable-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 99.98%);
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .learn-more-btn {
    pointer-events: auto;
    margin: ${({ theme }) => theme.spacing(0, 4)};
  }
`;

export default function CertificationStatusElement({ certification, isAdmin, isPsTeamMember, periods, currentDate, ...props }) {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isActive = certification.platformStatus === CertificationPlatformStatus.ACTIVE;
  const isInactive = certification.platformStatus === CertificationPlatformStatus.INACTIVE;
  const { id: certificationId, certificationSteps, currentCycle } = certification;
  const isBaselineAssessment = isBaseline(certification);
  const currentCertificationStep = getCurrentCertificationStep(certificationSteps);
  const params = {
    certification,
    isBaseline: isBaselineAssessment,
    currentCertificationCycle: currentCycle,
    currentCertificationStep,
    isPsTeamMember,
    skipActionButtonMetaGeneration: true,
    periods,
    currentDate
  };
  const statusConfig = getStatusConfig(params);
  const {
    certificationStatus,
    isCertified,
    title,
    certificationCycleYear,
    assessmentType,
    score,
    assessmentWindow,
    periodMeta
  } = statusConfig;
  const periodsParams = {
    certificationId: certification.id,
    certificationStatus,
    certificationCycle: currentCycle,
    certificationSteps,
    isPsTeamMember,
    isAdmin,
    periodMeta,
    periods,
    currentDate
  };
  const periodsWithMetadata = getCurrentCyclePeriodsMetadata(periodsParams);
  const highlightColor = COLOR_BY_ASSESSMENT_TYPE[assessmentType];

  return (
    <ContainerCard
      disabled={!isActive}
      type={certification.assessmentType.title}
      isInactive={isInactive}
      {...props}
    >
      <Link to={certificationId && generatePath(routes.CERTIFICATION, { certificationId })}>
        <LogoWrapper
          updatedAt={certification?.lastUpdated}
          type={certification?.assessmentType?.title}
          width={LOGO_SIZE}
          height="100%"
        />
      </Link>
      <Grid container>
        <Grid
          container
          item
          lg={7}
          md={12}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          pl={3}
          py={2}>
          <Typography variant="subtitle1">
            {title}
          </Typography>
          <Box>
            <CertificationCountdown
              period={assessmentWindow.period}
              isCurrent={assessmentWindow.isCurrent}
              remainingTime={assessmentWindow.remainingTime}
              isLabelTop />
          </Box>
        </Grid>
        <Grid
          container
          item
          lg={2}
          md={12}
          justifyContent={isLg ? 'flex-start' : 'flex-end'}
          pl={isLg ? 3 : 0}
          alignContent="center">
          {isCertified && (
            <BadgeIcon
              assessmentType={assessmentType}
              score={score}
              year={certificationCycleYear}
              width={54}
              currentDate={currentDate} />
          )}
        </Grid>
        <Grid
          container
          item
          lg={3}
          md={12}
          justifyContent={isLg ? 'flex-start' : 'flex-end'}
          pt={1}
          pb={2}
          pl={isLg ? 3 : 0}>
          <CertificationPeriods
            periods={periodsWithMetadata}
            highlightColor={highlightColor}
            isVertical={!isLg || isXs} />
        </Grid>
      </Grid>
      <PartnerMenu certification={certification} />
    </ContainerCard >
  );
}

export {
  LOGO_SIZE,
  LogoWrapper,
  CurrentCycleLabel
};
